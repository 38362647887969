.bios-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.individual-container {
    width: 600px;
    height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
    padding-top: 5px;
    
}

.buttons {
    display: flex;
    padding: 5px 0px;
}

.details {
    height: 50%;
    width: 50%;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 0px;
    color: #46494C;
}

button {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 20px;
    margin: 10px;
    color: #46494C;
    background-color: #d8d8b3;
    box-shadow: 1px 1px 4px #46494c;
    border: none;
    cursor: pointer;
}
