.App {
  text-align: center;
  height: 100%
}

img {
  margin: 10px
}

html, body {
  height: 100%
}

