@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');

body, main {
    height: 100%;
    background-color: #c6d8af;
}


.homelink {
    text-decoration: none;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 36px;
    color: #46494C;
    border-radius: 3px;
}

.content {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.blurb {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 20px;

    color: #46494C;
}

.name {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 0px;
    color: #46494C;
}
.me {
    border-radius: 50%;
    height: 300px;
    width: 300px;
  }
