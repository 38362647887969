.project-container {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    
}

img {
    height: 400px;
    width: 400px;
    margin-top: 20px;
}

.title {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 0px;
    color: #46494C;
}

.type {
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 0px;
    color: #46494C;
}

.tech {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 10px;
    color: #46494C;
}

.title, .type {
    margin-top: 0px;
    margin-bottom: 0px;
}

.code, .website {
    text-decoration: none;
    font-weight: 600;
    padding: 20px;
    font-family: 'Inter', sans-serif;
    color: #4c5c68;
}

